import React from 'react';
import styled from 'styled-components';

import DAOGovernance from './components/DAOGovernance';
import MainStar from './components/MainStar';
import RoadMap from './components/RoadMap/RoadMap';
import Footer from './components/footer/Footer';
import GameServicesSection from './components/gameServicesSection/GameServicesSection';
import Header from './components/header/Header';
import MainSection from './components/mainSection/MainSection';
import Tokenomics from './components/tokenomics/Tokenomics';

import useScrollToTarget from './hooks/useScrollToTarget';

import orange from './assets/background/orange_dust.svg';
import green from './assets/background/green_dust.svg';
import blue from './assets/background/blue_dust.svg';
import orbit from './assets/background/main_orbit.svg';
import looper from './assets/background/Looper.svg';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import PlatformService from './components/sub/PlatformService';
import Web3Games from './components/sub/Web3Games';
import Nfts from './components/sub/Nfts';
import Distribution from './components/distribution/Distribution';
import ScheduleRelease from './components/scheduleRelease/ScheduleRelease';
import PDF from './components/PDF';

const DustContainer = styled.div`
  background:
    url(${orange}) no-repeat calc(50% - 730px) 2150px,
    url(${green}) no-repeat calc(50% + 730px) 3300px,
    url(${looper}) no-repeat calc(50% + 590px) 3700px,
    url(${blue}) no-repeat calc(50% - 550px) 3800px,
    url(${green}) no-repeat calc(50% + 730px) 4900px,
    url(${orbit}) no-repeat calc(50%) 4500px;
  background-size: auto, 1000px, auto, auto, auto, 3000px;

  @media (max-width: 768px) {
    background:
      url(${orange}) no-repeat calc(50% - 150px) 1750px,
      url(${green}) no-repeat calc(50% + 250px) 2400px,
      url(${looper}) no-repeat calc(50% - 500px) 2360px,
      url(${blue}) no-repeat calc(50% - 300px) 3100px,
      url(${looper}) no-repeat calc(50% - -450px) 3750px,
      url(${green}) no-repeat calc(50% + 280px) 4550px,
      url(${orbit}) no-repeat calc(50%) 4500px;
    background-size: 500px, 800px, 1050px, 1250px, 900px, 900px, 1500px;
  }
`;

function App() {
  const navTabs = [
    useScrollToTarget('Ssthing'),
    useScrollToTarget('GameServices'),
    useScrollToTarget('DaoGovernance'),
    useScrollToTarget('Tokenomics'),
    useScrollToTarget('Roadmap'),
    useScrollToTarget('Main'),
  ];

  const mainIdArr = ['Main', 'Ssthing'];

  return (
    <>
      <BrowserRouter>
        <Header navTabs={navTabs} />
        <Routes>
          <Route
            path="/"
            element={
              <>
                <MainStar />
                <DustContainer>
                  <MainSection id={mainIdArr} navTabs={navTabs} />
                  <GameServicesSection id="GameServices" navTabs={navTabs} />
                  <DAOGovernance id="DaoGovernance" navTabs={navTabs} />
                  <Tokenomics id="Tokenomics" navTabs={navTabs} />
                  <RoadMap id="Roadmap" navTabs={navTabs} />
                  <Footer />
                </DustContainer>
              </>
            }
          ></Route>
          <Route path="/platformservice" element={<PlatformService />}></Route>
          <Route path="/web3game" element={<Web3Games />}></Route>
          <Route path="/nfts" element={<Nfts />}></Route>
          <Route path="/distribution" element={<Distribution />}></Route>
          <Route path="/schedulerelease" element={<ScheduleRelease />}></Route>
          <Route path="/whitepaper" element={<PDF />}></Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
