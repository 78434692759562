import styled from 'styled-components';
import star1 from '../assets/background/star1.svg';
import star2 from '../assets/background/star2.svg';
import star3 from '../assets/background/star3.svg';
import star4 from '../assets/background/star4.svg';
import star5 from '../assets/background/star5.svg';
import React from 'react';

const Star = styled.span`
  position: absolute;
`;
const Star1 = styled(Star)`
  background: url(${star1}) no-repeat 50% 50%;
  background-size: contain;
  width: 74px;
  height: 74px;

  @media (max-width: 768px) {
    width: 37px;
    height: 37px;
  }
`;

const Star2 = styled(Star)`
  background: url(${star2}) no-repeat 50% 50%;
  background-size: contain;
  width: 61px;
  height: 60px;
`;

const Star3 = styled(Star)`
  background: url(${star3}) no-repeat 50% 50%;
  background-size: contain;
  width: 56px;
  height: 60px;
`;

const Star4 = styled(Star)`
  background: url(${star4}) no-repeat 50% 50%;
  background-size: contain;
  width: 54px;
  height: 50px;
`;

const Star5 = styled(Star)`
  background: url(${star5}) no-repeat 50% 50%;
  background-size: contain;
  width: 107px;
  height: 97px;
`;

const MainStar1 = styled(Star1)`
  top: 150px;
  left: calc(50% + 500px);

  @media (min-width: 388px) and (max-width: 768px) {
    top: 100px;
    left: calc(50% + 100px);
  }

  @media (max-width: 387px) {
    top: 100px;
    left: calc(50% + 100px);
  }
`;

const MainStar2 = styled(Star4)`
  top: 200px;
  left: calc(50% + 580px);

  @media (max-width: 768px) {
    width: 29px;
    height: 27px;
    top: 130px;
    left: calc(50% + 139px);
    z-index: 10;
  }
`;

const MainStar3 = styled(Star2)`
  top: 300px;
  left: calc(50% - 600px);

  @media (max-width: 768px) {
    width: 38px;
    height: 40px;
    top: 130px;
    left: calc(50% - 139px);
  }
`;

const MainStar4 = styled(Star2)`
  top: 750px;
  left: calc(50% + 580px);
  @media (max-width: 768px) {
    width: 30px;
    height: 32px;
    top: 602px;
    left: calc(50% - 111px);
  }
`;

const SSTINGStar1 = styled(Star4)`
  top: 1440px;
  left: calc(50% - 660px);

  @media (max-width: 768px) {
    display: none;
  }
`;

const SSTINGStar2 = styled(Star1)`
  top: 1540px;
  left: calc(50% - 650px);

  @media (min-width: 388px) and (max-width: 768px) {
    top: 828px;
    left: calc(50% + 100px);
    transform: rotate(70deg);
  }

  @media (max-width: 387px) {
    top: 828px;
    left: calc(50% + 100px);
    transform: rotate(70deg);
  }
`;

const MobileSSTINGStar3 = styled(Star2)`
  display: none;
  @media (min-width: 388px) and (max-width: 768px) {
    display: block;
    width: 37px;
    height: 36px;
    top: 1078px;
    left: calc(50% - 134px);
  }

  @media (max-width: 387px) {
    display: block;
    width: 37px;
    height: 36px;
    top: 1078px;
    left: calc(50% - 134px);
  }
`;

const MobileSSTINGStar4 = styled(Star3)`
  display: none;
  @media (min-width: 388px) and (max-width: 768px) {
    display: block;
    top: 1490px;
    left: calc(50% + 113px);
  }

  @media (max-width: 387px) {
    display: block;
    top: 1490px;
    left: calc(50% + 113px);
  }
`;

const GameService1 = styled(Star2)`
  top: 2210px;
  left: calc(50% + 500px);

  @media (min-width: 388px) and (max-width: 768px) {
    width: 45px;
    height: 44px;
    top: 1743px;
    left: calc(50% + 117px);
  }

  @media (max-width: 387px) {
    width: 45px;
    height: 44px;
    top: 1743px;
    left: calc(50% + 117px);
  }
`;

const GameService2 = styled(Star5)`
  top: 2650px;
  left: calc(50% - 700px);
  width: 81px;
  height: 73px;

  @media (min-width: 388px) and (max-width: 768px) {
    width: 53px;
    height: 48px;
    top: 2520px;
    left: calc(50% - 113px);
  }
  @media (max-width: 387px) {
    width: 53px;
    height: 48px;
    top: 2472px;
    left: calc(50% - 113px);
  }
`;

const DAO1 = styled(Star2)`
  top: 2950px;
  left: calc(50% + 550px);

  @media (min-width: 388px) and (max-width: 768px) {
    width: 40px;
    height: 39px;
    top: 2613px;
    left: calc(50% + 130px);
  }

  @media (max-width: 387px) {
    width: 40px;
    height: 39px;
    top: 2613px;
    left: calc(50% + 130px);
  }
`;

const DAO2 = styled(Star1)`
  top: 3700px;
  left: calc(50% - 600px);

  @media (min-width: 388px) and (max-width: 768px) {
    top: 3363px;
    left: calc(50% - 168px);
    transform: rotate(80deg);
  }

  @media (max-width: 387px) {
    top: 3363px;
    left: calc(50% - 168px);
    transform: rotate(80deg);
  }
`;

const Token1 = styled(Star1)`
  top: 3900px;
  left: calc(50% + 450px);

  @media (min-width: 388px) and (max-width: 768px) {
    width: 34px;
    height: 34px;
    top: 3500px;
    left: calc(50% + 80px);
  }

  @media (max-width: 387px) {
    width: 34px;
    height: 34px;
    top: 3500px;
    left: calc(50% + 80px);
  }
`;

const Token2 = styled(Star4)`
  top: 3950px;
  left: calc(50% + 550px);

  @media (min-width: 388px) and (max-width: 768px) {
    width: 25px;
    height: 23px;
    top: 3527px;
    left: calc(50% + 113px);
  }

  @media (max-width: 387px) {
    width: 25px;
    height: 23px;
    top: 3527px;
    left: calc(50% + 113px);
  }
`;

const Token3 = styled(Star4)`
  top: 4600px;
  left: calc(50% - 600px);

  @media (min-width: 388px) and (max-width: 768px) {
    width: 22px;
    height: 20px;
    top: 4386px;
    left: calc(50% - 186px);
  }

  @media (max-width: 387px) {
    width: 22px;
    height: 20px;
    top: 4386px;
    left: calc(50% - 186px);
  }
`;

const Token4 = styled(Star1)`
  top: 4660px;
  left: calc(50% - 570px);

  @media (min-width: 388px) and (max-width: 768px) {
    width: 29px;
    height: 30px;
    top: 4416px;
    left: calc(50% - 175px);
  }

  @media (max-width: 387px) {
    width: 29px;
    height: 30px;
    top: 4416px;
    left: calc(50% - 175px);
  }
`;

const Road1 = styled(Star2)`
  top: 5530px;
  left: calc(50% + 600px);

  @media (min-width: 388px) and (max-width: 768px) {
    width: 31px;
    height: 33px;
    top: 5348px;
    left: calc(50% + 126px);
  }

  @media (max-width: 387px) {
    width: 31px;
    height: 33px;
    top: 5348px;
    left: calc(50% + 126px);
  }
`;

const MainStar = () => {
  return (
    <>
      <MainStar1 />
      <MainStar2 />
      <MainStar3 />
      <MainStar4 />
      <SSTINGStar1 />
      <SSTINGStar2 />
      <MobileSSTINGStar3 />
      <MobileSSTINGStar4 />
      <GameService1 />
      <GameService2 />
      <DAO1 />
      <DAO2 />
      <Token1 />
      <Token2 />
      <Token3 />
      <Token4 />
      <Road1 />
    </>
  );
};

export default React.memo(MainStar);
