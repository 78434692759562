import React from 'react';
import { Props } from '../type/types';
import styled from 'styled-components';

type TextStyleT = {
  fontSize: string;
  fontWeight: number;
  fontalign: 'left' | 'right' | 'center' | 'justify';
};

const P = styled.p<TextStyleT>`
  font-size: ${(props) => (props.fontSize ? props.fontSize : '40px')};
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : 400)};
  text-align: ${(props) => (props.fontalign ? props.fontalign : 'left')};
  background: var(--text, linear-gradient(#fff 20.42%, #818181 84.7%));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin: 0;
  padding: 3px 0;
`;

const GradientText = ({
  children,
  styles,
  className,
}: {
  children: Props['children'];
  styles: TextStyleT;
  className?: string;
}) => {
  return (
    <P {...styles} className={className}>
      {children}
    </P>
  );
};

export default React.memo(GradientText);
