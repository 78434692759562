import star1 from '../../assets/background/star1.svg';
import star2 from '../../assets/background/star2.svg';
import star3 from '../../assets/background/star4.svg';
import styled from 'styled-components';

const Star = styled.span`
  position: absolute;
`;

const Star1 = styled(Star)`
  background: url(${star1}) no-repeat 50% 50%;
  background-size: contain;
  width: 74px;
  height: 74px;
  top: 250px;
  left: calc(50% - 300px);

  @media (max-width: 768px) {
    width: 37px;
    height: 37px;
    top: 700px;
    left: calc(50% + 130px);
  }
`;

const Star2 = styled(Star)`
  background: url(${star2}) no-repeat 50% 50%;
  background-size: contain;
  width: 74px;
  height: 74px;
  top: 862px;
  left: calc(50% + 250px);

  @media (max-width: 768px) {
    width: 40px;
    height: 39px;
    top: 80px;
    left: calc(50% + 110px);
  }
`;

const Star3 = styled(Star)`
  display: none;
  @media (max-width: 768px) {
    background: url(${star3}) no-repeat 50% 50%;
    background-size: contain;
    display: block;
    width: 27px;
    height: 25px;
    top: 670px;
    left: calc(50% + 120px);
  }
`;

const DistributionBg = () => {
  return (
    <>
      <Star1 />
      <Star2 />
      <Star3 />
    </>
  );
};

export default DistributionBg;
