import styled from 'styled-components';

const RoadContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: 38px;
`;

const ContextP = styled.p<RightT>`
  color: #b4c5cb;
  font-size: 1.5rem;
  font-weight: 300;
  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    font-size: 1rem;
    text-align: ${(props) => (props.right === 'right' ? 'right' : 'left')};
  }
`;

type RightT = {
  right: 'right' | 'left';
};

const DateP = styled.p<RightT>`
  font-size: 1.5rem;
  font-weight: 600;
  width: 100%;
  text-align: ${(props) => (props.right === 'right' ? 'right' : 'left')};

  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  row-gap: 5px;

  @media (max-width: 768px) {
    width: 125px;
  }
`;

const Road = ({ context, date, styles }: { context: string; date: string; styles: RightT }) => {
  return (
    <>
      <RoadContainer>
        <ContentContainer>
          <ContextP right={styles.right}>{context}</ContextP>
          <DateP right={styles.right}>{date}</DateP>
        </ContentContainer>
      </RoadContainer>
    </>
  );
};

export default Road;
