import React, { useState } from 'react';
import styled from 'styled-components';
import PageTitle from '../../common/PageTitle';
import GradientText from '../../common/GradientText';
import { DarkGrayBox } from '../../ common/box/styles';
import arrow from '../../assets/arrow.svg';
import { NavTabsProps } from '../../type/types';
import { useNavigate } from 'react-router-dom';
import { useScrollAnimation } from '../../hooks/useScrollAnimation';
import { OpacityDiv } from '../style';

const TokenContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  margin-bottom: 292px;

  @media (max-width: 768px) {
    margin-bottom: 160px;
    padding: 0 30px;
    .resize_title {
      font-size: 1.875rem;
    }
    .resize_text {
      font-size: 1.25rem;
    }
  }
`;

const SectionTitle = styled.h3`
  color: #fff;
  font-size: 1.875rem;
  font-weight: 700;
  text-align: center;
  margin-bottom: 22px;
  margin-top: 8px;

  @media (max-width: 768px) {
    font-size: 1.375rem;
  }
`;

const UtilityContent = styled.div`
  width: 482px;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const InfoContent = styled.div`
  width: 594px;
  margin-bottom: 32px;

  @media (max-width: 768px) {
    width: 100%;
    margin-bottom: 20px;
  }
`;

const Section = styled.section`
  display: flex;
  justify-content: center;
  column-gap: 50px;
  margin-top: 100px;
  opacity: 0;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 20px;
    margin-top: 30px;
  }
`;

const InformationParent = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 15px 100px;

  @media (max-width: 768px) {
    grid-gap: 15px 24px;
  }
`;

const LeftP = styled.p`
  color: #898ca9;
  font-size: 1.125rem;
  margin: 0;

  @media (max-width: 768px) {
    font-size: 0.875rem;
  }
`;

const InformationRightP = styled(LeftP)`
  color: white;
  font-size: 1.25rem;
  font-weight: 600;
  margin: 0;

  @media (max-width: 768px) {
    font-size: 1rem;
    &:nth-last-child(1) {
      font-size: 0.9375rem;
    }
  }
`;

const UtilityPContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 15px;
  margin-bottom: 32px;
  @media (max-width: 768px) {
    margin-bottom: 15px;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  column-gap: 50px;

  @media (max-width: 768px) {
    column-gap: 0;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    row-gap: 10px;
  }
`;

const Button = styled.button`
  cursor: pointer;
  color: #fff;
  height: 50px;
  border: 2px solid transparent;
  border-radius: 36px;
  background-image: linear-gradient(black, black), linear-gradient(to right, red, purple);
  background-origin: border-box;
  background-clip: padding-box, border-box;

  margin-top: 16px;
  padding: 5px 50px 5px 50px;
  display: flex;
  justify-content: center;
  column-gap: 10px;
  align-items: center;

  @media (max-width: 768px) {
    justify-content: space-between;
    padding: 5px 20px 5px 20px;
    margin-top: 0px;
    font-size: 0.9375rem;
    font-weight: 600;
    height: 40px;
  }
`;

const AlignCenter = styled.div`
  display: grid;
  justify-items: center;

  @media (max-width: 768px) {
    gap: 20px;
  }
`;

const GrayBox = styled(DarkGrayBox)`
  @media (max-width: 768px) {
    width: 100%;
    padding: 10px;
  }
`;

const TitleSection = styled(PageTitle)`
  margin-bottom: 32px;
  @media (max-width: 768px) {
    margin-bottom: 20px;
  }
`;

const Tokenomics = ({ id, navTabs }: { id: string; navTabs: NavTabsProps[] }) => {
  const navigate = useNavigate();
  const { ref, isInViewport } = useScrollAnimation();
  const [flag, setFlag] = useState(false);

  if (isInViewport && !flag) {
    setFlag(true);
  }
  return (
    <TokenContainer id={id} ref={navTabs[3].targetRef as React.RefObject<HTMLDivElement>}>
      <OpacityDiv id="token1" ref={ref} className={flag ? 'fadeInUp' : ''}>
        <TitleSection className="resize_title">Tokenomics</TitleSection>
        <GradientText
          className="resize_text"
          styles={{
            fontSize: '1.875rem',
            fontWeight: 500,
            fontalign: 'left',
          }}
        >
          SST is the utility and governance token via crypto game industry.
        </GradientText>
      </OpacityDiv>
      <Section id="token2" ref={ref} className={flag ? 'fadeInUp' : ''}>
        <AlignCenter>
          <GrayBox>
            <InfoContent>
              <SectionTitle>Information</SectionTitle>
              <InformationParent>
                <LeftP>The name of token</LeftP>
                <InformationRightP>SSTING</InformationRightP>
                <LeftP>Token symbol</LeftP>
                <InformationRightP>SST</InformationRightP>
                <LeftP>Network</LeftP>
                <InformationRightP>BEP20</InformationRightP>
                <LeftP>Total supply</LeftP>
                <InformationRightP>1,000,000,000 SST</InformationRightP>
              </InformationParent>
            </InfoContent>
          </GrayBox>
          <ButtonContainer>
            <Button
              type="button"
              onClick={() => {
                navigate('/distribution');
              }}
            >
              DISRTIBUTION <img src={arrow} alt="arrow" />
            </Button>
            <Button
              type="button"
              onClick={() => {
                navigate('/schedulerelease');
              }}
            >
              SCHEDULE RELEASE <img src={arrow} alt="arrow" />
            </Button>
          </ButtonContainer>
        </AlignCenter>
        <GrayBox>
          <UtilityContent>
            <SectionTitle>Utility</SectionTitle>
            <UtilityPContainer>
              <LeftP>Transaction fee</LeftP>
              <LeftP>Stake DAO governance to earn reward</LeftP>
              <LeftP>Participate governance vote</LeftP>
              <LeftP>Trade and swap game items</LeftP>
              <LeftP>Earn in game Prize</LeftP>
              <LeftP>Donation</LeftP>
              <LeftP>Buy and sell NFTs</LeftP>
            </UtilityPContainer>
          </UtilityContent>
        </GrayBox>
      </Section>
    </TokenContainer>
  );
};

export default Tokenomics;
