import star1 from '../../assets/background/star3.svg';
import star2 from '../../assets/background/star1.svg';
import styled from 'styled-components';
import { Star } from './style';

const Star1 = styled(Star)`
  background: url(${star1}) no-repeat 50% 50%;
  background-size: contain;
  width: 140.45px;
  height: 150px;
  top: 208px;
  left: calc(50% - 420px);

  @media (max-width: 768px) {
    width: 64px;
    height: 68px;
    top: 200px;
    left: calc(50% - 185px);
  }
`;

const Star2 = styled(Star)`
  background: url(${star2}) no-repeat 50% 50%;
  background-size: contain;
  width: 74px;
  height: 74px;
  top: 600px;
  left: calc(50% + 320px);

  @media (max-width: 768px) {
    width: 38px;
    height: 38px;
    top: 450px;
    left: calc(50% + 118px);
  }
`;

const PlatformBg = () => {
  return (
    <>
      <Star1 />
      <Star2 />
    </>
  );
};

export default PlatformBg;
