import styled from 'styled-components';
import orangeDust from '../../assets/background/orange_dust.svg';
import Web3Game from '../../assets/sub/web3.png';
import looper from '../../assets/background/Looper.svg';
import { Img, LoadingDiv, MT120, SubP, backgroundContainer, subContainer } from './style';
import PageTitle from '../../common/PageTitle';
import Web3GBg from './Web3GBg';
import useEnterPage from '../../hooks/useEnterPage';
import { useEffect, useState } from 'react';

const BgContainer = styled.div`
  background:
    url(${orangeDust}) no-repeat calc(50% - 680px) -450px,
    url(${looper}) no-repeat calc(50% + 50px) -50px;
  background-size: 1700px 1143px;
  ${backgroundContainer}

  @media (max-width: 768px) {
    background:
      url(${orangeDust}) no-repeat calc(50% - 280px) -450px,
      url(${looper}) no-repeat calc(50% - 220px) -50px;
    background-size: 1000px, 800px;
  }
`;

const Web3Container = styled.div`
  ${subContainer}
`;

const Web3P = styled(SubP)`
  width: 90%;
`;

const WebLoading = styled(LoadingDiv)`
  height: 423px;
  @media (max-width: 768px) {
    height: 238px;
  }
`;

const Web3Games = () => {
  useEnterPage();

  const [imageLoaded, setImageLoaded] = useState(false);

  const preloadImage = () => {
    const image = new Image();
    image.src = Web3Game;
    image.onload = () => {
      setImageLoaded(true);
    };
  };

  useEffect(() => {
    preloadImage();
  }, []);

  return (
    <>
      <BgContainer>
        <Web3Container>
          <MT120>
            <PageTitle className="sub">Web3 Games</PageTitle>
          </MT120>
          {imageLoaded ? <Img src={Web3Game} alt="platformServiceImg" loading="lazy" /> : <WebLoading></WebLoading>}
          <Web3P>
            Web3 game is one of the significant contents of SSTING ecosystem. It is sort of playground for users. Users
            could directly play games and take various reward or prize. In addition, users could experience online
            social life, such as making new friends who have never met or creating a guild with other users. Moreover,
            the team supports game service providers. There could be partnership and bounty programs. Therefore, both
            users and service providers could take their own benefits and effective experiences.
          </Web3P>
        </Web3Container>
        <Web3GBg />
      </BgContainer>
    </>
  );
};

export default Web3Games;
