import star1 from '../../assets/background/star1.svg';
import star2 from '../../assets/background/star2.svg';
import star3 from '../../assets/background/star6.svg';
import styled from 'styled-components';

const Star = styled.span`
  position: absolute;
`;

const Star1 = styled(Star)`
  background: url(${star1}) no-repeat 50% 50%;
  background-size: contain;
  width: 74px;
  height: 74px;
  top: 120px;
  left: calc(50% + 550px);

  @media (max-width: 768px) {
    display: none;
  }
`;

const Star2 = styled(Star)`
  background: url(${star2}) no-repeat 50% 50%;
  background-size: contain;
  width: 74px;
  height: 74px;
  top: 970px;
  left: calc(50% - 500px);

  @media (max-width: 768px) {
    display: none;
  }
`;

const Star3 = styled(Star)`
  display: none;

  @media (max-width: 768px) {
    display: block;
    background: url(${star3}) no-repeat 50% 50%;
    background-size: contain;
    width: 47px;
    height: 52px;
    top: 160px;
    left: calc(50% - 180px);
  }
`;

const ScheduleReleaseBg = () => {
  return (
    <>
      <Star1 />
      <Star2 />
      <Star3 />
    </>
  );
};

export default ScheduleReleaseBg;
