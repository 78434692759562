import styled from 'styled-components';
import star1 from '../../assets/background/star1.svg';
import star2 from '../../assets/background/star2.svg';
import star3 from '../../assets/background/star4.svg';
import { Star } from './style';

const Star1 = styled(Star)`
  background: url(${star1}) no-repeat 50% 50%;
  background-size: contain;
  width: 74px;
  height: 74px;
  top: 960px;
  left: calc(50% - 520px);

  @media (max-width: 768px) {
    width: 37px;
    height: 37px;
    top: 805px;
    left: calc(50% + 100px);
  }
`;

const Star2 = styled(Star)`
  background: url(${star2}) no-repeat 50% 50%;
  background-size: contain;
  width: 61px;
  height: 60px;
  top: 80px;
  left: calc(50% + 500px);

  @media (max-width: 768px) {
    width: 40px;
    height: 39px;
    top: 70px;
    left: calc(50% + 120px);
  }
`;

const Star3 = styled(Star)`
  background: url(${star3}) no-repeat 50% 50%;
  background-size: contain;
  width: 54px;
  height: 50px;
  top: 910px;
  left: calc(50% - 550px);

  @media (max-width: 768px) {
    width: 27px;
    height: 25px;
    top: 760px;
    left: calc(50% + 80px);
  }
`;

const Web3GBg = () => {
  return (
    <>
      <Star1 />
      <Star2 />
      <Star3 />
    </>
  );
};

export default Web3GBg;
