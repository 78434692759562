import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import PageTitle from '../../common/PageTitle';
import star from '../../assets/background/roadmapStar.svg';
import line from '../../assets/Line.svg';
import Road from './Road';
import Circle from './Circle';
import { NavTabsProps } from '../../type/types';
import { useScrollAnimation } from '../../hooks/useScrollAnimation';

const RoadMapContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  margin-bottom: 127px;

  @media (max-width: 768px) {
    margin-bottom: 0px;
    padding: 0 30px;
    .resize_title {
      font-size: 1.875rem;
    }
  }
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 77px;
  margin-bottom: 120px;
  position: relative;

  @media (max-width: 768px) {
    margin-bottom: 20px;
  }
`;

const Img = styled.img``;

const Q = css`
  display: flex;
  align-items: center;
  column-gap: 40px;
  position: absolute;
  opacity: 0;

  @media (max-width: 768px) {
    column-gap: 20px;
  }
`;

const Q3 = styled.div`
  ${Q}
  top: 58px;
  left: calc(50% - 379px);

  @media (max-width: 768px) {
    left: calc(50% - 171px);
  }
`;

const Q2 = styled.div`
  ${Q}
  top: 218px;
  left: calc(50% - 9px);
`;

const Q1 = styled.div`
  ${Q}
  top: 376px;
  left: calc(50% - 482px);

  @media (max-width: 768px) {
    left: calc(50% - 170px);
  }
`;

const Q4 = styled.div`
  ${Q}
  top: 537px;
  left: calc(50% - 9px);
`;

const RoadMapStar = styled.img`
  @media (max-width: 768px) {
    width: 34px;
    height: 34px;
  }
`;

const RoadMap = ({ id, navTabs }: { id: string; navTabs: NavTabsProps[] }) => {
  const { ref, isInViewport } = useScrollAnimation();
  const [flag, setFlag] = useState(false);

  if (isInViewport && !flag) {
    setFlag(true);
  }
  return (
    <>
      <RoadMapContainer id={id} ref={navTabs[4].targetRef as React.RefObject<HTMLDivElement>}>
        <PageTitle className="resize_title">
          Roadmap <RoadMapStar src={star} alt="" />
        </PageTitle>
        <ContentContainer>
          <Img src={line} alt="" />
          <Q3 id="Q3" ref={ref} className={flag ? 'fadeInUpDelay1' : ''}>
            <Road context="The Game part2 OPEN." date="2024. 3Q" styles={{ right: 'right' }} />
            <Circle />
          </Q3>
          <Q2 id="Q2" className={flag ? 'fadeInUpDelay2' : ''}>
            <Circle right={true} />
            <Road context="Token listing Version update." date="2024. 2Q" styles={{ right: 'left' }} />
          </Q2>
          <Q1 id="Q1" className={flag ? 'fadeInUpDelay3' : ''}>
            <Road context="1st Game OPEN Version update." date="2024.1Q" styles={{ right: 'right' }} />
            <Circle />
          </Q1>
          <Q4 id="Q4" className={flag ? 'fadeInUpDelay4' : ''}>
            <Circle right={true} />
            <Road context="1st Game OBT Token listing." date="2023. 4Q" styles={{ right: 'left' }} />
          </Q4>
        </ContentContainer>
      </RoadMapContainer>
    </>
  );
};

export default RoadMap;
