import React from 'react';
import styled from 'styled-components';

import GradientText from '../../common/GradientText';
import PageTitle from '../../common/PageTitle';
import Lolling from './Lolling';

import { NavTabsProps } from '../../type/types';
import { useScrollAnimation } from '../../hooks/useScrollAnimation';
import { OpacityDiv } from '../style';

const BgContainer = styled.div`
  width: 100%;
  position: relative;
  @media (max-width: 768px) {
    background: transparent;
  }
`;

const SstingContainer = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 53px;
  margin-bottom: 135px;

  @media (max-width: 768px) {
    gap: 10px;
    padding: 0 30px;
    .resize_title {
      font-size: 2.5rem;
    }
    .resize_text {
      font-size: 1rem;
    }
  }
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: 768px) {
    gap: 30px;
    margin-bottom: 80px;
  }
`;

const SstingSection = ({ id, navTabs }: { id: string; navTabs: NavTabsProps[] }) => {
  const { ref, isInViewport } = useScrollAnimation();

  return (
    <BgContainer>
      <SstingContainer id={id} ref={navTabs[0].targetRef as React.RefObject<HTMLDivElement>}>
        <TitleWrapper>
          <OpacityDiv
            id="ssting1"
            ref={ref}
            className={`${document.getElementById('ssting1')?.className} ${isInViewport ? 'fadeInUp' : ''}`}
          >
            <PageTitle className="resize_title">SSTING ?</PageTitle>
          </OpacityDiv>
          <OpacityDiv
            id="ssting2"
            ref={ref}
            className={`${document.getElementById('ssting2')?.className} ${isInViewport ? 'fadeInUp' : ''}`}
          >
            <GradientText
              className="resize_text"
              styles={{
                fontSize: '1.625rem',
                fontWeight: 0,
                fontalign: 'center',
              }}
            >
              SSTING ecosystem is user friendly and has huge expandability.
              <br />
              There are three categories of SSTING ecosystem platform service, web3 games and NFTs
            </GradientText>
          </OpacityDiv>
        </TitleWrapper>

        <OpacityDiv
          id="ssting3"
          ref={ref}
          className={`${document.getElementById('ssting3')?.className} ${isInViewport ? 'fadeInUp' : ''}`}
        ></OpacityDiv>
        <Lolling />
      </SstingContainer>
    </BgContainer>
  );
};

export default SstingSection;
