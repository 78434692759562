import styled from 'styled-components';

type LabelStyleT = {
  width?: string;
  bgcolor: string;
};

const LabelContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: 12px;
  margin-bottom: 20px;
`;

const Circle = styled.div<LabelStyleT>`
  border-radius: 50%;
  width: ${(props) => (props.width ? props.width : '15px')};
  height: ${(props) => (props.width ? props.width : '15px')};
  background-color: ${(props) => (props.bgcolor ? props.bgcolor : '#fff')};
`;

const LabelP = styled.p`
  color: #b8b8b8;
  width: 214px;

  @media (max-width: 768px) {
    font-size: 0.75rem;
    width: 127px;
  }
`;

const Label = ({ styles, context }: { styles: LabelStyleT; context: string }) => {
  return (
    <LabelContainer>
      <Circle {...styles}></Circle>
      <LabelP>{context}</LabelP>
    </LabelContainer>
  );
};

export default Label;
