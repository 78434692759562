import React, { useState } from 'react';
import styled from 'styled-components';
import GradientText from '../common/GradientText';
import PageTitle from '../common/PageTitle';
import { NavTabsProps } from '../type/types';
import { OpacityDiv } from './style';
import { useScrollAnimation } from '../hooks/useScrollAnimation';

const DAOContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  margin-bottom: 228px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (max-width: 768px) {
    margin-bottom: 160px;
    padding: 0 30px;
    .resize_title {
      font-size: 1.875rem;
    }
    .resize_text {
      font-size: 1.25rem;
    }
  }
`;

const Mb35 = styled.div`
  margin-bottom: 35px;
  opacity: 0;
  @media (max-width: 768px) {
    margin-top: 20px;
    margin-bottom: 65px;
  }
`;

const W60 = styled.div`
  display: flex;
  flex-direction: column;
  width: 60%;
  row-gap: 30px;
  opacity: 0;

  @media (max-width: 768px) {
    width: 90%;
    row-gap: 45px;
  }
`;

const RightContainer = styled.section`
  display: flex;
  justify-content: flex-end;
`;

const DAOGovernance = ({ id, navTabs }: { id: string; navTabs: NavTabsProps[] }) => {
  const { ref, isInViewport } = useScrollAnimation();
  const [flag, setFlag] = useState(false);

  if (isInViewport && !flag) {
    setFlag(true);
  }
  return (
    <DAOContainer id={id} ref={navTabs[2].targetRef as React.RefObject<HTMLDivElement>}>
      <OpacityDiv id="DAO1" ref={ref} className={flag ? 'fadeInUp' : ''}>
        <PageTitle className="resize_title" styles={{ fontalign: 'left' }}>
          DAO Governance
        </PageTitle>
      </OpacityDiv>

      <Mb35 id="DAO2" ref={ref} className={flag ? 'fadeInUp' : ''}>
        <GradientText
          className="resize_text"
          styles={{
            fontSize: '1.875rem',
            fontWeight: 500,
            fontalign: 'left',
          }}
        >
          The genesis of the SSTING project is expanding and creating various game services, such as Gamefi and web3
          games. For the genesis, DAO governance takes duties and incentives.
        </GradientText>
      </Mb35>

      <RightContainer>
        <W60 id="rightContainer" ref={ref} className={flag ? 'fadeInUp' : ''}>
          <GradientText
            className="resize_text"
            styles={{
              fontSize: '1.875rem',
              fontWeight: 500,
              fontalign: 'left',
            }}
          >
            Duties <br />
            Participate in SSTING ecosystem <br /> Contribute to development and expand of the project. It should take
            pretty important roles in the ecosystem of the project.
          </GradientText>
          <GradientText
            className="resize_text"
            styles={{
              fontSize: '1.875rem',
              fontWeight: 500,
              fontalign: 'left',
            }}
          >
            Incentives <br />
            Voting power <br />
            Governance reward via SST
          </GradientText>
        </W60>
      </RightContainer>
    </DAOContainer>
  );
};

export default DAOGovernance;
