import { Link } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';

import icBottomArrow from '../../assets/lineBottomArrow.svg';
import moMenu from '../../assets/hamburger_menu.svg';
import moMenuClose from '../../assets/close.svg';
import icWorld from '../../assets/world.svg';
import { NavTabsProps } from '../../type/types';
import useLocationEndpoint from '../../hooks/useLocationEndpoint';
import { useEffect, useState } from 'react';
import useCotoTarget from '../../hooks/useGotoTarget';

const boxFade = keyframes`
  0% {
    transform: translateY(-5px);
    opacity: 0;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
`;

const menuActive = keyframes`
  0% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
`;

const MoMenu = styled.button`
  &.mo_block {
    display: none;
  }

  @media (max-width: 768px) {
    &.mo_block {
      display: block;
    }
  }
`;

const MoCloseBtn = styled.button`
  position: absolute;
  right: 20px;
  top: 20px;
  z-index: 10px;
`;

const NavContainer = styled.nav`
  position: relative;
  display: flex;
  gap: 30px;

  .mo_block {
    display: none;
  }
  @media (max-width: 768px) {
    display: none;
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
    gap: 0;
    background: linear-gradient(45deg, #898ca9 0%, #1a1b23 0%);
    height: 100vh;

    &::before {
      content: '';
      display: block;
      width: calc(100% - 80%);
      height: 100%;
      background-color: #000;
    }

    &.active {
      display: flex;
    }
    .mo_none {
      display: none;
    }
    .mo_block {
      display: block;
    }
  }
`;

const MenuWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 30px;

  @media (max-width: 768px) {
    padding: 40px 30px;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
  }
`;

const MenuList = styled.ul`
  position: relative;
  align-items: center;
  display: flex;
  gap: 24px;

  button {
    font-size: 1.125rem;
    font-weight: 500;
    padding: 0;
  }

  li > button {
    transition: 0.3s;
  }

  li > button.active {
    transition: 0.3s;
    background-image: linear-gradient(45deg, #ff0000 0%, #df18ff 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: ${menuActive} 0.3s;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: self-start;

    li > a {
      animation: ${boxFade} 0.5s linear;
    }

    li > button {
      animation: ${boxFade} 0.5s linear;
    }
  }
`;

const DepthOneBtn = styled.button`
  display: flex;
  align-items: center;
  gap: 5px;

  &.ic_arrow {
    &::after {
      content: '';
      display: block;
      width: 10px;
      height: 6px;
      background-image: url(${icBottomArrow});
    }
  }

  &:hover + .depth2 {
    display: flex;
  }
`;

const DepthTwo = styled.ul`
  position: absolute;
  flex-direction: column;
  gap: 5px;
  padding-top: 10px;
  display: none;
  align-items: flex-start;
  animation: ${boxFade} 0.5s linear;

  &:hover {
    display: flex;
  }

  @media (max-width: 768px) {
    position: relative;
    display: flex;
    padding-left: 20px;
    opacity: 0.8;
  }
`;

const LanguageBtn = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 5px 7px;
  border: 1px solid #fff;
  border-radius: 50px;

  span {
    font-size: 1rem;
    font-weight: 500;
    transform: translateY(1px);
  }

  &::before {
    content: '';
    display: block;
    width: 15px;
    height: 15px;
    background-image: url(${icWorld});
  }

  &::after {
    content: '';
    display: block;
    width: 10px;
    height: 6px;
    background-image: url(${icBottomArrow});
  }
`;

const MunuButtom = styled.div`
  width: auto;
  display: flex;
  flex-direction: column;
  align-items: self-start;
  gap: 20px;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const Nav = ({ navTabs }: { navTabs: NavTabsProps[] }) => {
  const ispath = useLocationEndpoint();
  const [isMoMenu, setIsMoMenu] = useState(false);
  const handleGotoWrap = useCotoTarget();

  const handleMenuToggle = () => {
    setIsMoMenu((prev) => !prev);
  };

  const handleMenuhidden = () => {
    setIsMoMenu(false);
  };

  useEffect(() => {
    if (ispath !== '') {
      navTabs.map((el) => el.isTarget && el.setIsTarget(false));
      if (ispath === 'platformservice' || ispath === 'web3game' || ispath === 'nfts') {
        navTabs[0].setIsTarget(true);
      }
      if (ispath === 'distribution' || ispath === 'schedulerelease') {
        navTabs[3].setIsTarget(true);
      }
    }
    if (ispath === '') {
      navTabs.map((el) => el.isTarget === true && el.setIsTarget(false));
    }
  }, [ispath]);

  return (
    <>
      <MoMenu className="mo_block" onClick={handleMenuToggle}>
        <img src={moMenu} alt="moMenu" />
      </MoMenu>

      <NavContainer className={`mo_nav ${isMoMenu ? 'active' : ''}`}>
        <MoCloseBtn className="mo_block" onClick={handleMenuToggle}>
          <img src={moMenuClose} alt="close btn"></img>
        </MoCloseBtn>

        <MenuWrapper>
          <MenuList>
            <li>
              <DepthOneBtn
                onClick={() => {
                  handleMenuhidden();
                  navTabs[0].scrollToTarget();
                  handleGotoWrap('Ssthing');
                }}
                className={`depth1 ic_arrow ${navTabs[0].isTarget ? 'active' : ''}`}
              >
                SSTING
              </DepthOneBtn>
              <DepthTwo className="depth2">
                <li>
                  <Link to="/platformservice" onClick={handleMenuhidden}>
                    Platform Service
                  </Link>
                </li>
                <li>
                  <Link to="/web3game" onClick={handleMenuhidden}>
                    Web3 Games
                  </Link>
                </li>
                <li>
                  <Link to="/nfts" onClick={handleMenuhidden}>
                    NFTs
                  </Link>
                </li>
              </DepthTwo>
            </li>
            <li>
              <button
                onClick={() => {
                  handleMenuhidden();
                  navTabs[1].scrollToTarget();
                  handleGotoWrap('GameServices');
                }}
                className={navTabs[1].isTarget ? 'active' : ''}
              >
                Game Services
              </button>
            </li>
            <li>
              <button
                onClick={() => {
                  handleMenuhidden();
                  navTabs[2].scrollToTarget();
                  handleGotoWrap('DaoGovernance');
                }}
                className={navTabs[2].isTarget ? 'active' : ''}
              >
                DAO Governance
              </button>
            </li>
            <li>
              <DepthOneBtn
                onClick={() => {
                  handleMenuhidden();
                  navTabs[3].scrollToTarget();
                  handleGotoWrap('Tokenomics');
                }}
                className={`depth1 ic_arrow ${navTabs[3].isTarget ? 'active' : ''}`}
              >
                Tokenomics
              </DepthOneBtn>
              <DepthTwo className="depth2">
                <li>
                  <Link to="/distribution" onClick={handleMenuhidden}>
                    Distribution
                  </Link>
                </li>
                <li>
                  <Link to="/schedulerelease" onClick={handleMenuhidden}>
                    Schedule Release
                  </Link>
                </li>
              </DepthTwo>
            </li>
            <li>
              <button
                onClick={() => {
                  handleMenuhidden();
                  navTabs[4].scrollToTarget();
                  handleGotoWrap('Roadmap');
                }}
                className={navTabs[4].isTarget ? 'active' : ''}
              >
                Roadmap
              </button>
            </li>
            <li>
              <Link to="/whitepaper" target="_blank">
                White paper
              </Link>
            </li>
          </MenuList>

          <MunuButtom>
            <LanguageBtn>
              <span>ENG</span>
            </LanguageBtn>
          </MunuButtom>
        </MenuWrapper>
      </NavContainer>
    </>
  );
};

export default Nav;
