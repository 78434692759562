import { css } from 'styled-components';

export const PinckGradient = css`
  background: #ff0000;
  background: linear-gradient(10deg, #ff0000 0%, #df18ff 100%);
`;

export const GrayGradient = css`
  background: #fff;
  background: var(--text, linear-gradient(#fff 20.42%, #818181 84.7%));
`;

export const BoxShadow = css`
  box-shadow: 0px 5px 4px 0px rgba(0, 0, 0, 0.25);
`;
