import styled from 'styled-components';
import blueDust from '../../assets/background/blue_dust.svg';
import looper from '../../assets/background/Looper.svg';
import platformService from '../../assets/sub/platformservice.png';
import PageTitle from '../../common/PageTitle';
import { Img, LoadingDiv, MT120, SubP, backgroundContainer, subContainer } from './style';
import PlatformBg from './PlatformBg';
import useEnterPage from '../../hooks/useEnterPage';
import { useEffect, useState } from 'react';

const BgContainer = styled.div`
  background:
    url(${blueDust}) no-repeat calc(50% + 450px) -280px,
    url(${looper}) no-repeat calc(50% - 430px) -50px;
  background-size: 1120px 960px;
  ${backgroundContainer}

  @media (max-width: 768px) {
    background:
      url(${blueDust}) no-repeat calc(50% - 150px) -450px,
      url(${looper}) no-repeat calc(50% + 170px) 50px;
    background-size: 1120px, 650px;
  }
`;

const PlatformContainer = styled.div`
  ${subContainer}
`;

const PlatformP = styled(SubP)`
  width: 90%;
`;

const MT50 = styled.div`
  margin-top: 50px;
  display: flex;
  justify-content: center;

  @media (max-width: 768px) {
    margin-top: 0px;
  }
`;

const PlatformLoding = styled(LoadingDiv)`
  height: 335px;
  @media (max-width: 768px) {
    height: 188px;
  }
`;

const PlatformService = () => {
  useEnterPage();
  const [imageLoaded, setImageLoaded] = useState(false);

  const preloadImage = () => {
    const image = new Image();
    image.src = platformService;
    image.onload = () => {
      setImageLoaded(true);
    };
  };

  useEffect(() => {
    preloadImage();
  }, []);

  return (
    <>
      <BgContainer>
        <PlatformContainer>
          <MT120>
            <PageTitle className="sub">Platform Service</PageTitle>
          </MT120>
          <MT50>
            {imageLoaded ? (
              <Img src={platformService} alt="platformServiceImg" loading="lazy" />
            ) : (
              <PlatformLoding></PlatformLoding>
            )}
          </MT50>
          <PlatformP>
            The platform service is the foundation of the SSTING ecosystem. The ecosystem will be expanded based on the
            platform. Users can be provided various services via the platform. In addition, users could use SST deposit,
            withdraw, trade and swap services, and governance reward or other rewards would be paid through the
            platform. Other commodities, such as game items or NFTs could be traded also. The team will keep trying to
            improve user convenience and inherent interactions between users and service and do its best to activate and
            expand the platform to build various infrastructures.
          </PlatformP>
        </PlatformContainer>
        <PlatformBg />
      </BgContainer>
    </>
  );
};

export default PlatformService;
