import star1 from '../../assets/background/star5.svg';
import star2 from '../../assets/background/star2.svg';
import styled from 'styled-components';
import { Star } from './style';

const Star1 = styled(Star)`
  background: url(${star1}) no-repeat 50% 50%;
  background-size: contain;
  width: 107px;
  height: 96px;
  top: 500px;
  left: calc(50% - 550px);

  @media (max-width: 768px) {
    width: 50px;
    height: 44px;
    top: 800px;
    left: calc(50% - 150px);
  }
`;

const Star2 = styled(Star)`
  background: url(${star2}) no-repeat 50% 50%;
  background-size: contain;
  width: 61px;
  height: 60px;
  top: 200px;
  left: calc(50% + 320px);

  @media (max-width: 768px) {
    width: 30px;
    height: 30px;
    top: 180px;
    left: calc(50% + 80px);
  }
`;

const NftBg = () => {
  return (
    <>
      <Star1 />
      <Star2 />
    </>
  );
};

export default NftBg;
