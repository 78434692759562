import styled from 'styled-components';
import logo from '../../assets/logo.svg';
import mail from '../../assets/mail.svg';
import IconP from './IconP';
import { AlpaP, FlexDiv } from './style';
import telegram from '../../assets/sns/telegram.svg';
import twitter from '../../assets/sns/twitter.svg';
import discord from '../../assets/sns/discord.svg';
import medium from '../../assets/sns/medium.svg';
import youtube from '../../assets/sns/youtube.svg';

const FooterContainer = styled.footer`
  padding-left: 120px;
  padding-right: 120px;
  padding-top: 74px;
  margin-bottom: 62px;
  background-color: #000;

  @media (max-width: 768px) {
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
  }
`;

const TopSection = styled.section`
  padding-top: 35px;
  padding-bottom: 35px;
  display: flex;
  justify-content: space-around;
  align-content: center;
  border-top: 2px solid rgba(151, 151, 151, 0.5);
  border-bottom: 1px solid rgba(151, 151, 151, 0.3);
  margin-bottom: 14px;

  @media (max-width: 768px) {
    flex-direction: column;
    padding-top: 18px;
  }
`;

const BottomSection = styled(FlexDiv).attrs({ as: 'section' })`
  display: flex;
  justify-content: flex-end;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    row-gap: 20px;
    margin-bottom: 67px;
  }
`;

const ContactContainer = styled.section`
  width: 417px;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const PhoneEmailWrapper = styled(FlexDiv)`
  margin-top: 10px;

  @media (max-width: 768px) {
    margin-top: 16px;
    flex-direction: column;
    row-gap: 16px;
  }
`;

const FollowContainer = styled(FlexDiv)`
  margin-top: 30px;
  align-items: center;

  > p {
    width: 100px;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;

    > p {
      width: auto;
    }
  }
`;

const SnsWrapper = styled(FlexDiv)`
  column-gap: 14px;
  width: 100%;
  justify-content: flex-start;
  @media (max-width: 768px) {
    margin-top: 8px;
  }
`;

const Img = styled.img`
  width: 200px;
  @media (max-width: 768px) {
    width: 142px;
  }
`;

const Footer = () => {
  const snsArr = [
    { sns: telegram, id: 1 },
    { sns: twitter, id: 2 },
    { sns: discord, id: 3 },
    { sns: medium, id: 4 },
    { sns: youtube, id: 5 },
  ];
  return (
    <FooterContainer>
      <TopSection>
        <Img src={logo} alt="logo" />
        <ContactContainer>
          <PhoneEmailWrapper>
            <IconP svg={mail} context="info@ssting.io" />
          </PhoneEmailWrapper>
          <FollowContainer>
            <AlpaP>Follow us</AlpaP>
            <SnsWrapper>
              {snsArr.map((sns) => {
                return <img src={sns.sns} key={sns.id} />;
              })}
            </SnsWrapper>
          </FollowContainer>
        </ContactContainer>
      </TopSection>
      <BottomSection>
        <AlpaP>© SSTING PTE. LTD. 2023.</AlpaP>
      </BottomSection>
    </FooterContainer>
  );
};

export default Footer;
