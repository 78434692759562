import styled from 'styled-components';
import { NormalP } from './style';

const FlexContainer = styled.div`
  display: flex;
  column-gap: 16px;
  align-items: center;

  @media (max-width: 768px) {
    width: 70%;
  }
`;

const IconP = ({ svg, context }: { svg: string; context: string }) => {
  return (
    <FlexContainer>
      <img src={svg} alt="icon" />
      <NormalP>{context}</NormalP>
    </FlexContainer>
  );
};

export default IconP;
