import styled from 'styled-components';
import stroke from '../../assets/background/roadmapstroke.svg';
import strokeMo from '../../assets/background/roadmapstroke_mo.svg';

const CircleContainer = styled.div`
  display: flex;

  .mo_none {
    display: block;
  }
  .mo_block {
    display: none;
  }

  @media (max-width: 768px) {
    .mo_none {
      display: none;
    }
    .mo_block {
      display: block;
    }
  }
`;

const CircleDiv = styled.div`
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background: linear-gradient(45deg, #ff0000 0%, #df18ff 100%);
  position: relative;
`;

const Img = styled.img`
  transform: rotate(180deg); /* 각도 */
`;

const Circle = ({ right }: { right?: boolean }) => {
  return (
    <div>
      {right ? (
        <CircleContainer>
          <CircleDiv></CircleDiv>
          <Img src={stroke} className="mo_none" alt="" />
          <Img src={strokeMo} className="mo_block" alt="" />
        </CircleContainer>
      ) : (
        <CircleContainer>
          <img src={stroke} className="mo_none" alt="" />
          <img src={strokeMo} className="mo_block" alt="" />
          <CircleDiv></CircleDiv>
        </CircleContainer>
      )}
    </div>
  );
};

export default Circle;
