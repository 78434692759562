import styled, { keyframes } from 'styled-components';
import PageTitle from '../../common/PageTitle';
import piechart from '../../assets/sub/piechart.png';
import Label from './Label';
import DistributionBg from './DistributionBg';
import bg from '../../assets/background/orange_dust.svg';
import lopper from '../../assets/background/Looper.svg';
import useEnterPage from '../../hooks/useEnterPage';
import { useEffect, useState } from 'react';

export const boxFade = keyframes`
  0% {
    transform: translateY(-5px);
    opacity: 0;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
`;

const BgContainer = styled.div`
  width: 100%;
  background:
    url(${bg}) no-repeat calc(50% - 730px) -250px,
    url(${lopper}) no-repeat calc(50% - 1000px) -500px;
  background-size: contain, 1500px;
  display: flex;
  justify-content: center;
  position: relative;

  @media (max-width: 768px) {
    background:
      url(${bg}) no-repeat calc(50% - 230px) -350px,
      url(${lopper}) no-repeat calc(50% - 200px) 200px;
    background-size: 800px, 600px;
  }
`;

const MT = styled.div`
  margin-top: 150px;
`;

const DistributionContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 120px;
  margin-right: 120px;
`;

const ContentContainer = styled.main`
  display: flex;
  align-items: center;
  margin-top: 140px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    margin-top: 70px;
  }
`;

const LabelDiv = styled.div`
  margin-left: 60px;
  @media (max-width: 768px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-top: 50px;
    margin-left: 0px;
  }
`;

const Img = styled.img`
  width: 90%;
  animation: ${boxFade} 0.5s linear;
`;

const DistributionLoading = styled.div`
  width: 660px;
  height: 421px;

  @media (max-width: 768px) {
    width: 277px;
    height: 193px;
  }
`;

const Distribution = () => {
  const [isLoading, setIsLoading] = useState(true);
  const distributionText = [
    { value: 'Private', id: 1, color: '#FF8282' },
    { value: 'Liquidity', id: 2, color: '#F3F4F5' },
    { value: 'Operation', id: 3, color: '#FFB5B6' },
    { value: 'Partners & Advisors', id: 4, color: '#FFF' },
    { value: 'Marketing & Dev', id: 5, color: '#D22424' },
    { value: 'Treasury', id: 6, color: '#FFD7D8' },
    { value: 'Ecosystem Fund', id: 7, color: '#FF5656' },
    { value: 'Utility Pool', id: 8, color: '#FFF0F1' },
  ];

  useEnterPage();

  const [imageLoaded, setImageLoaded] = useState(false);

  const preloadImage = () => {
    const image = new Image();
    image.src = piechart;
    image.onload = () => {
      setImageLoaded(true);
    };
  };

  useEffect(() => {
    preloadImage();
  }, []);

  return (
    <>
      <BgContainer>
        <DistributionContainer>
          <MT>
            <PageTitle
              styles={{
                fontalign: 'center',
              }}
              className="distribution"
            >
              Distribution
            </PageTitle>
          </MT>
          <ContentContainer>
            {imageLoaded ? (
              <Img
                src={piechart}
                alt="distribution chart"
                loading="lazy"
                onLoad={(e) => {
                  setIsLoading((prev) => false);
                }}
              />
            ) : (
              <DistributionLoading></DistributionLoading>
            )}

            <LabelDiv>
              {distributionText.map((el) => (
                <Label
                  styles={{
                    bgcolor: el.color,
                  }}
                  context={el.value}
                  key={el.id}
                />
              ))}
            </LabelDiv>
          </ContentContainer>
        </DistributionContainer>
        <DistributionBg />
      </BgContainer>
    </>
  );
};

export default Distribution;
