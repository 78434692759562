import styled from 'styled-components';
import NftBg from './NftBg';
import greenDust from '../../assets/background/green_dust.svg';
import looper from '../../assets/background/Looper.svg';
import PageTitle from '../../common/PageTitle';
import nfts from '../../assets/sub/NFTs.png';
import { Img, LoadingDiv, MT120, SubP, backgroundContainer, subContainer } from './style';
import useEnterPage from '../../hooks/useEnterPage';
import { useEffect, useState } from 'react';

const BgContainer = styled.div`
  background:
    url(${greenDust}) no-repeat calc(50% + 700px) -580px,
    url(${looper}) no-repeat calc(50% + 700px) -550px;
  background-size:
    1000px 1312px,
    1120px 960px;
  ${backgroundContainer}

  @media (max-width: 768px) {
    background:
      url(${greenDust}) no-repeat calc(50% + 200px) -300px,
      url(${looper}) no-repeat calc(50% + 200px) -50px;
    background-size: 800px, 820px;
  }
`;

const NftsContainer = styled.div`
  ${subContainer}
`;

const NftsP = styled(SubP)`
  width: 90%;
`;

const NftLoading = styled(LoadingDiv)`
  height: 360px;
  @media (max-width: 768px) {
    height: 202px;
  }
`;

const Nfts = () => {
  useEnterPage();
  const [imageLoaded, setImageLoaded] = useState(false);

  const preloadImage = () => {
    const image = new Image();
    image.src = nfts;
    image.onload = () => {
      setImageLoaded(true);
    };
  };

  useEffect(() => {
    preloadImage();
  }, []);

  return (
    <BgContainer>
      <NftsContainer>
        <MT120>
          <PageTitle className="sub">NFTs</PageTitle>
        </MT120>
        {imageLoaded ? <Img src={nfts} alt="nftImg" loading="lazy" /> : <NftLoading></NftLoading>}

        <NftsP>
          NFTs issued from the public chain can be purchased by users through a variety of means, including games and
          market transactions. Through NFTs, users are ensured the exclusivity of digital goods derived from each game
          or platforms such as character and membership cards and other special stuff. The value of play performance can
          be realized and applied in the game through the free trade of NFTs. To make a platform environment for the use
          of NFTs that is more convenient and secure, service providers will establish and enhance their service
          offering. An example of such a space is the game, where users can take various benefits in the game.
        </NftsP>
      </NftsContainer>
      <NftBg />
    </BgContainer>
  );
};

export default Nfts;
