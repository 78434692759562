import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import Nav from '../nav/Nav';

import logo from '../../assets/logo.svg';
import { NavTabsProps } from '../../type/types';
import useLocationEndpoint from '../../hooks/useLocationEndpoint';

const HeaderContainer = styled.header`
  position: fixed;
  top: 0;
  z-index: 50;
  width: 100%;
  padding: 15px 0;
  transition: 0.3s;

  &.active_header {
    background-color: black;
  }
`;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;

  @media (max-width: 768px) {
    padding: 0 30px;
  }
`;

const Img = styled.img`
  width: 200px;
  margin-bottom: -8px;
  @media (max-width: 768px) {
    width: 150px;
  }
`;

const Header = ({ navTabs }: { navTabs: NavTabsProps[] }) => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const SCROLL_SIZE = scrollPosition < 80;

  const ispath = useLocationEndpoint();

  const updateScroll = () => {
    setScrollPosition(window.scrollY || document.documentElement.scrollTop);
  };

  useEffect(() => {
    window.addEventListener('scroll', updateScroll);
  });

  return (
    <>
      {ispath !== 'whitepaper' && (
        <HeaderContainer className={SCROLL_SIZE ? '' : 'active_header'}>
          <Container>
            <h2>
              <Link
                to="/"
                onClick={() => {
                  navTabs[5].scrollToTarget();
                }}
              >
                <Img src={logo} alt="ssting_logo" />
              </Link>
            </h2>
            <Nav navTabs={navTabs} />
          </Container>
        </HeaderContainer>
      )}
    </>
  );
};

export default Header;
