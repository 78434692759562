import styled from 'styled-components';
import pdf1 from '../assets/pdf/pdf-01.png';
import pdf2 from '../assets/pdf/pdf-02.png';
import pdf3 from '../assets/pdf/pdf-03.png';
import pdf4 from '../assets/pdf/pdf-04.png';
import pdf5 from '../assets/pdf/pdf-05.png';
import pdf6 from '../assets/pdf/pdf-06.png';
import pdf7 from '../assets/pdf/pdf-07.png';
import pdf8 from '../assets/pdf/pdf-08.png';
import pdf9 from '../assets/pdf/pdf-09.png';
import pdf10 from '../assets/pdf/pdf-10.png';
import pdf11 from '../assets/pdf/pdf-11.png';
import pdf12 from '../assets/pdf/pdf-12.png';
import pdf13 from '../assets/pdf/pdf-13.png';
import pdf14 from '../assets/pdf/pdf-14.png';
import pdf15 from '../assets/pdf/pdf-15.png';
import pdf16 from '../assets/pdf/pdf-16.png';
import pdf17 from '../assets/pdf/pdf-17.png';
import pdf18 from '../assets/pdf/pdf-18.png';

const PdfContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Img = styled.img`
  width: 90%;
  max-width: 800px;
`;

const PDF = () => {
  const pdfArr = [
    { value: pdf1, id: 1 },
    { value: pdf2, id: 2 },
    { value: pdf3, id: 3 },
    { value: pdf4, id: 4 },
    { value: pdf5, id: 5 },
    { value: pdf6, id: 6 },
    { value: pdf7, id: 7 },
    { value: pdf8, id: 8 },
    { value: pdf9, id: 9 },
    { value: pdf10, id: 10 },
    { value: pdf11, id: 11 },
    { value: pdf12, id: 12 },
    { value: pdf13, id: 13 },
    { value: pdf14, id: 14 },
    { value: pdf15, id: 15 },
    { value: pdf16, id: 16 },
    { value: pdf17, id: 17 },
    { value: pdf18, id: 18 },
  ];
  return (
    <PdfContainer>
      {pdfArr.map((pdf) => (
        <Img src={pdf.value} key={pdf.id} />
      ))}
    </PdfContainer>
  );
};

export default PDF;
