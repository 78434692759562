import React, { useState } from 'react';
import styled from 'styled-components';

import GradientText from '../../common/GradientText';
import PageTitle from '../../common/PageTitle';
import { LightGrayBox } from '../../ common/box/styles';
import { PinckGradient, GrayGradient, BoxShadow } from './style';

import Cont1 from '../../assets/gameservice1.png';
import Cont2 from '../../assets/gameservice2.png';
import Cont3 from '../../assets/gameservice3.png';
import CircleArrow from '../../assets/circleToparrow.svg';
import Looper from '../../assets/background/Looper.svg';

import { NavTabsProps } from '../../type/types';
import { useScrollAnimation } from '../../hooks/useScrollAnimation';
import { OpacityDiv } from '../style';
import scrollToTop from '../../utils/scrollToTop';

const BgContainer = styled.div`
  width: 100%;
  background: url(${Looper}) no-repeat calc(50% - -567px) 20px;
  background-size: 1050px;
  position: relative;

  @media (max-width: 768px) {
    background: url(${Looper}) no-repeat calc(50% + 280px) 5px;
    background-size: 800px;
  }
`;

const GameServicesContainer = styled.section`
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  gap: 53px;
  margin-bottom: 135px;

  @media (max-width: 768px) {
    gap: 40px;
    margin-bottom: 160px;
    .resize_title {
      font-size: 1.875rem;
    }
    .resize_text {
      font-size: 1.25rem;
    }
    .resize_subtitle {
      font-size: 1.25rem;
    }
    .mo_none {
      display: none;
    }
  }
`;

const ServicesMoScroll = styled.div`
  @media (max-width: 768px) {
    padding: 0 20px 10px 20px;
    overflow-y: auto;
    overflow-x: scroll;
  }
`;

const ServicesContens = styled.section`
  width: 100%;
  display: flex;
  gap: 24px;
  opacity: 0;

  @media (max-width: 768px) {
    width: 245%;
    gap: 13px;
  }
`;

const TitleWrapper = styled.div`
  @media (max-width: 768px) {
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
`;

const CardWrapper = styled.div`
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;
  @media (max-width: 768px) {
    gap: 15px;
  }
`;

const GrayBoxWrapper = styled(LightGrayBox)`
  position: relative;
  padding-bottom: 100%;
  ${BoxShadow}
`;

const ContImg = styled.img`
  width: 90%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  &.imgsize_80 {
    width: 80%;
  }
`;

const PointTextWrapper = styled.span`
  position: relative;
  margin: 0 10px;
  z-index: 1;
`;

const GrayText = styled.p`
  font-size: 13px;
  font-weight: 300;
  color: #c4c4c4;

  @media (max-width: 768px) {
    white-space: normal;
    font-size: 10px;
  }
`;

const PointText = styled.span`
  position: relative;
  border-radius: 20px;
  display: inline-block;
  z-index: 1;
  padding: 10px;
  ${GrayGradient}
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const PointTextBg = styled.span`
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: -1;
  border-radius: 20px;
  ${PinckGradient}
`;

const GotoTopBtn = styled.button`
  display: flex;
  justify-content: space-between;
  border-radius: 10px;
  font-size: 24px;
  padding: 23px 27px;
  ${PinckGradient}
  ${BoxShadow}
`;

const GameServicesSection = ({ id, navTabs }: { id: string; navTabs: NavTabsProps[] }) => {
  const { ref, isInViewport } = useScrollAnimation();
  const [flag, setFlag] = useState(false);
  if (isInViewport && !flag) {
    setFlag(true);
  }

  return (
    <BgContainer>
      <GameServicesContainer id={id} ref={navTabs[1].targetRef as React.RefObject<HTMLDivElement>}>
        <TitleWrapper>
          <OpacityDiv id="gameservice" ref={ref} className={flag ? 'fadeInUp' : ''}>
            <PageTitle
              className="resize_title"
              styles={{
                fontalign: 'left',
              }}
            >
              Game Services
            </PageTitle>
          </OpacityDiv>
          <OpacityDiv id="gameservice2" className={flag ? 'fadeInUp' : ''}>
            <GradientText
              className="resize_text"
              styles={{
                fontSize: '40px',
                fontWeight: 0,
                fontalign: 'left',
              }}
            >
              There will be various crypto games on
              <PointTextWrapper>
                <PointText>SSTING</PointText>
                <PointTextBg />
              </PointTextWrapper>
              ecosystem.
              <br />
              Horse racing(tentative name) is the first game, and OBT
              <br /> open in
              <PointTextWrapper>
                <PointText>2023 4Q</PointText>
                <PointTextBg />
              </PointTextWrapper>
            </GradientText>
          </OpacityDiv>
        </TitleWrapper>

        <ServicesMoScroll>
          <ServicesContens id="serviceContens" ref={ref} className={flag ? 'fadeInUp' : ''}>
            <CardWrapper>
              <GrayBoxWrapper spacing="0">
                <ContImg src={Cont1} alt="Horse Racing" />
              </GrayBoxWrapper>
              <TextWrapper>
                <PageTitle
                  className="resize_subtitle"
                  styles={{
                    fontalign: 'left',
                    fontSize: '1.625rem',
                  }}
                >
                  Horse Racing
                </PageTitle>
                <GrayText>
                  Play-to-Earn (P2E) offers a gaming paradigm in which players can earn actual incentives or virtual
                  assets by playing the game. This particular game is in the sports game genre and simulates the
                  experience of horse racing.
                </GrayText>
              </TextWrapper>
            </CardWrapper>
            <CardWrapper>
              <GotoTopBtn className="mo_none" onClick={scrollToTop}>
                SSTING
                <img src={CircleArrow} alt="arrow" />
              </GotoTopBtn>
              <GrayBoxWrapper spacing="0">
                <ContImg src={Cont2} alt="Cont img" />
              </GrayBoxWrapper>
              <TextWrapper>
                <PageTitle
                  className="resize_subtitle"
                  styles={{
                    fontalign: 'left',
                    fontSize: '1.625rem',
                  }}
                >
                  Coming Soon
                </PageTitle>
              </TextWrapper>
            </CardWrapper>
            <CardWrapper>
              <GrayBoxWrapper spacing="0">
                <ContImg src={Cont3} alt="Cont img" className="imgsize_80" />
              </GrayBoxWrapper>
              <TextWrapper>
                <PageTitle
                  className="resize_subtitle"
                  styles={{
                    fontalign: 'left',
                    fontSize: '1.625rem',
                  }}
                >
                  Coming Soon
                </PageTitle>
              </TextWrapper>
            </CardWrapper>
          </ServicesContens>
        </ServicesMoScroll>
      </GameServicesContainer>
    </BgContainer>
  );
};

export default GameServicesSection;
