import styled, { css, keyframes } from 'styled-components';

export const boxFade = keyframes`
  0% {
    transform: translateY(-5px);
    opacity: 0;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
`;

export const SubP = styled.p`
  color: rgba(255, 255, 255, 0.5);
  font-size: 1rem;
  animation: ${boxFade} 0.5s linear;

  @media (max-width: 768px) {
    font-size: 0.6875rem;
  }
`;

export const Star = styled.span`
  position: absolute;
`;

export const backgroundContainer = css`
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
`;

export const subContainer = css`
  max-width: 1440px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 70px;
  margin-bottom: 175px;
`;

export const MT120 = styled.div`
  margin-top: 150px;
`;

export const Img = styled.img`
  animation: ${boxFade} 0.5s linear;
  width: 600px;
  @media (max-width: 768px) {
    width: 90%;
  }
`;

export const LoadingDiv = styled.div`
  width: 600px;
  @media (max-width: 768px) {
    width: 90%;
  }
`;
