import styled from 'styled-components';

type BoxProps = {
  spacing?: string;
  // 추후 커스터마이징 가능하도록 옵션만 작성하기
  fontWeight?: 'medium' | 'bold';
  fontColor?: '#4a4a4a' | '#fff';
};

export const LightGrayBox = styled.div<BoxProps>`
  background: linear-gradient(45deg, #898ca9 0%, #1a1b23 0%);
  border-radius: 10px;
  padding: ${(props) => (props.spacing ? props.spacing : '0')};
`;

export const DarkGrayBox = styled.div<BoxProps>`
  background-color: #1a1b23;
  border-radius: 25px;
  padding: ${(props) => (props.spacing ? props.spacing : '24px')};
`;

export const GridentBox = styled.div`
  border-radius: 3px;
  padding: 15px 0;
  text-align: center;
  font-size: 18px;
`;

export const LightGrayGrident = styled(GridentBox)`
  background: #818181; // linear-gradient 호환 안되는 경우
  background: linear-gradient(45deg, #818181 0%, #fff 100%);
  color: #4a4a4a;
  font-weight: medium;
`;

export const DarkGrayGrident = styled(GridentBox)`
  background: #313131; // linear-gradient 호환 안되는 경우;
  background: linear-gradient(45deg, #313131 0%, #c2c2c2 100%);
  color: #fff;
  font-weight: bold;
  font-size: 18px;
  height: 31px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;

  @media (max-width: 768px) {
    font-size: 0.4375rem;
    height: 14px;
    width: 14px;
    font-weight: 400;
  }
`;

export const LightPinkGrident = styled(GridentBox)`
  background: #ff9898; // linear-gradient 호환 안되는 경우;
  background: linear-gradient(45deg, #ff9898 0%, #fff 100%);
  color: #4a4a4a;
  font-weight: medium;
`;

export const HotPinkGrident = styled(GridentBox)`
  background: #ff0000; // linear-gradient 호환 안되는 경우;
  background: linear-gradient(45deg, #ff0000 0%, #df18ff 100%);
  color: #fff;
  font-weight: medium;
`;
