import styled from 'styled-components';

export const NormalP = styled.p`
  font-size: 14px;
  font-weight: 400;
  margin: 0;
`;

export const AlpaP = styled(NormalP)`
  color: rgba(255, 255, 255, 0.7);
`;

export const FlexDiv = styled.div`
  display: flex;
  justify-content: space-between;
`;
