import styled from 'styled-components';
import { Props } from '../type/types';
import React from 'react';

type TitleStyleT = {
  fontSize?: string;
  fontalign?: string;
};

const TitleText = styled.h2<TitleStyleT>`
  font-size: ${(props) => (props.fontSize ? props.fontSize : '4.0625rem')};
  font-weight: 700;
  text-align: ${(props) => (props.fontalign ? props.fontalign : 'left')};
  color: #fff;

  @media (max-width: 768px) {
    &.distribution {
      font-size: 1.875rem; //30px;
    }

    &.sub {
      font-size: 2.1875rem;
    }
  }
`;

const PageTitle = ({
  children,
  styles,
  className,
}: {
  children: Props['children'];
  styles?: TitleStyleT;
  className?: string;
}) => {
  return (
    <TitleText {...styles} className={className}>
      {children}
    </TitleText>
  );
};

export default React.memo(PageTitle);
