import React, { useState } from 'react';
import styled from 'styled-components';

import GradientText from '../../common/GradientText';
import PageTitle from '../../common/PageTitle';
import SstingSection from '../sstingSection/SstingSection';
import mainGreenDust from '../../assets/background/main_green_dust.svg';
import mainBuleDust from '../../assets/background/main_blue_dust.svg';
import mainOrbit from '../../assets/background/main_orbit.svg';
import mainCircle2 from '../../assets/background/main_circle1_1.svg';
import mainGrayCircle from '../../assets/background/grayCircle_11zon.png';
import mainYellowCircle from '../../assets/background/yellowCircle_11zon.png';
import mainPinkCircle from '../../assets/background/sphere_11zon.png';
import GotoTopButton from '../../common/button/GotoTopButton';

import { NavTabsProps } from '../../type/types';
import { useScrollAnimation } from '../../hooks/useScrollAnimation';
import { OpacityDiv } from '../style';

const BgContainer = styled.div`
  width: 100%;
  background:
    url(${mainYellowCircle}) no-repeat calc(50% - 630px) 390px,
    url(${mainGrayCircle}) no-repeat calc(50% - 530px) 675px,
    url(${mainPinkCircle}) no-repeat calc(50% - -730px) 500px,
    url(${mainOrbit}) no-repeat calc(50% - 80px) -350px,
    url(${mainGreenDust}) no-repeat calc(50% - 580px) -870px,
    url(${mainBuleDust}) no-repeat calc(50% - -800px) -300px;
  background-size: 110px, 156px, 259px, 2560px, contain, contain;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;

  @media (max-width: 768px) {
    background:
      url(${mainYellowCircle}) no-repeat calc(50% - 170px) 200px,
      url(${mainGrayCircle}) no-repeat calc(100vw - 20vw) 550px,
      url(${mainPinkCircle}) no-repeat calc(100vw - 15vw) 100px,
      url(${mainOrbit}) no-repeat calc(50% - -10%) calc(50% - 50%),
      url(${mainGreenDust}) no-repeat calc(50% - 180px) -550px,
      url(${mainBuleDust}) no-repeat calc(50% - -200px) 0px;
    background-size: 70px, 40px, 100px, 1225px, 850px, 850px;
  }
`;

const MainContainer = styled.section`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 50px;
  padding: 180px 0 215px 0;

  @media (max-width: 768px) {
    width: 100%;
    padding: 180px 0 300px 0;
    gap: 30px;
    .resize_title {
      position: absolute;
      width: 100%;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      font-size: calc(100vw - 88.58vw);
    }
    .resize_text {
      font-size: 0.875rem;
      padding: 0 30px;
    }
  }
`;

const CircleBg = styled.div`
  height: 460px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: url(${mainCircle2}) no-repeat center center;

  @media (max-width: 768px) {
    position: relative;
    height: 0;
    padding-bottom: calc(100% - 20%);
    width: 100%;
    background-size: contain;
    margin-bottom: -40px;
  }
`;

const MainSection = ({ id, navTabs }: { id: string[]; navTabs: NavTabsProps[] }) => {
  const { ref, isInViewport } = useScrollAnimation();
  const [flag, setFlag] = useState(false);

  if (isInViewport && !flag) {
    setFlag(true);
  }
  return (
    <>
      <BgContainer>
        <MainContainer id={id[0]} ref={navTabs[5].targetRef as React.RefObject<HTMLDivElement>}>
          <CircleBg id="main1" className={`fadeInUp`}>
            <PageTitle
              className="resize_title"
              styles={{
                fontSize: '6.25rem',
                fontalign: 'center',
              }}
            >
              SSTING project
            </PageTitle>
          </CircleBg>

          <OpacityDiv id="main2" ref={ref} className={flag ? 'fadeInUp' : ''}>
            <GradientText
              className="resize_text"
              styles={{
                fontSize: '1.625rem',
                fontWeight: 0,
                fontalign: 'center',
              }}
            >
              Pursues the crypto platform service
              <br />
              which contributes the ecosystem of crypto game industry
            </GradientText>
          </OpacityDiv>
        </MainContainer>

        <GotoTopButton />
        <SstingSection id={id[1]} navTabs={navTabs} />
      </BgContainer>
    </>
  );
};

export default MainSection;
