import styled from 'styled-components';

import lineGotoArrow from '../../assets/lineGotoArrow.svg';
import BottomArrowImg from '../../assets/lineBottomArrowGradient.svg';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';

const LollingContContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  width: 100%;

  @media (max-width: 768px) {
    flex-direction: column-reverse;
    gap: 120px;
  }
`;

const LollingContainer = styled.section`
  max-width: 1122px;
  width: 100%;
  margin: 0 auto;
  margin-top: 30px;
  display: flex;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 20px;

    .resize_title {
      font-size: 2.5rem;
    }
    .resize_text {
      font-size: 0.875rem;
    }
  }
`;

const LollingWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  gap: 35px;
  border: 1px solid transparent;
  border-radius: 20px;
  border: 1px solid #fff;
  flex: 1;
  justify-content: center;
  height: 107px;
  transition: 0.3s;
  padding: 30px 68px;

  .cont_wrapper {
    display: none;
  }

  &.active {
    justify-content: none;
    background: #ff0000; // linear-gradient 호환 안되는 경우;
    background: linear-gradient(8deg, #ff0000 0%, #df18ff 100%);
    color: #fff;
    flex: 3;
    border-color: transparent;
    transition: 0.5s;
  }

  &.active .numbtn_wrapper {
    padding: 0;
    height: 34px;
  }

  &.active .cont_wrapper {
    display: flex;
    opacity: 1;
  }

  &.active .select_btn {
    background: #818181; // linear-gradient 호환 안되는 경우
    background: linear-gradient(45deg, #818181 0%, #fff 100%);
    color: #4a4a4a;
    border-color: transparent;
  }

  &.active .mo_goto_btn {
    display: flex;
  }

  @media (max-width: 768px) {
    gap: 10px;
    border-radius: 10px;
    background: none;
    padding: 15px 20px;
    border: 1px solid #616161;
    background: #000;
    .mo_disnone {
      display: none;
    }
    .cont_wrapper {
      display: flex;
    }
    &.active .mo_text {
      color: #fff;
    }
  }
`;

const NumberBtnWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 34px;

  @media (max-width: 768px) {
    flex-basis: 45px;
    padding: 0;
  }
`;

const CircleBtn = styled.button`
  position: relative;
  border-radius: 100px;
  border: 1px solid #fff;
  font-size: 1.625rem;

  & > span {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  @media (max-width: 768px) {
    color: #616161;
    border-color: #616161;
    font-size: 1.25rem;
  }
`;

const NumberBtn = styled(CircleBtn)`
  /* width: 21px;
  padding-bottom: 31px;
  height: 0; */

  width: 34px;
  padding: 0;
  height: 100%;

  &.select_btn {
    background: #ff0000; // linear-gradient 호환 안되는 경우;
    background: linear-gradient(45deg, #ff0000 0%, #df18ff 100%);
    border: 1px solid transparent;
  }

  @media (max-width: 768px) {
    width: 100%;
    &.select_btn {
      background: none;
      border: 1px solid #616161;
    }
  }
`;

const GotoBtn = styled(CircleBtn)`
  position: absolute;
  right: 36px;
  width: 34px;
  height: 34px;
  padding: 0;
  background: #818181; // linear-gradient 호환 안되는 경우
  background: linear-gradient(45deg, #818181 0%, #fff 100%);
  color: #4a4a4a;
  border: 1px solid transparent;

  img {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-40%, -40%);
  }

  @media (max-width: 768px) {
    width: 25px;
    height: 25px;
    display: none;
    right: 20px;

    img {
      width: 20px;
    }
  }
`;

const ContWrapper = styled.div`
  width: 100%;
  align-items: center;
  justify-content: center;
  gap: 30px;
`;

const ContText = styled.span`
  display: block;
  width: 100%;
  font-size: 1.625rem;
  font-weight: 500;
  padding-right: 30px;
  text-align: center;
  color: #fff;

  @media (max-width: 768px) {
    text-align: left;
    font-size: 1.0625rem;
    color: #616161;
  }
`;

const BottomArrow = styled.div`
  @media (max-width: 768px) {
    img {
      width: 15px;
    }
  }
`;

const Lolling = () => {
  const navigate = useNavigate();
  const [lollingCount, setLollingCount] = useState(0);

  const handleLolling = (num: number) => {
    setLollingCount(num);
  };

  return (
    <LollingContContainer>
      <BottomArrow>
        <img src={BottomArrowImg} alt="arrow" />
      </BottomArrow>
      <LollingContainer>
        <LollingWrapper
          onClick={() => handleLolling(1)}
          onMouseEnter={() => handleLolling(1)}
          className={lollingCount === 1 || lollingCount === 0 ? 'active' : ''}
        >
          <NumberBtnWrapper className="numbtn_wrapper">
            <NumberBtn className="select_btn">
              <span>1</span>
            </NumberBtn>
            <NumberBtn className="mo_disnone">
              <span>2</span>
            </NumberBtn>
            <NumberBtn className="mo_disnone">
              <span>3</span>
            </NumberBtn>
          </NumberBtnWrapper>
          <ContWrapper className="cont_wrapper">
            <ContText className="mo_text"> Platform Service </ContText>
            <GotoBtn
              className="mo_goto_btn"
              onClick={() => {
                navigate('/platformservice');
              }}
            >
              <img src={lineGotoArrow} alt="lineGotoArrow" />
            </GotoBtn>
          </ContWrapper>
        </LollingWrapper>
        <LollingWrapper
          onClick={() => handleLolling(2)}
          onMouseEnter={() => handleLolling(2)}
          className={lollingCount === 2 ? 'active' : ''}
        >
          <NumberBtnWrapper className="numbtn_wrapper">
            <NumberBtn className="mo_disnone">
              <span>1</span>
            </NumberBtn>
            <NumberBtn className="select_btn">
              <span>2</span>
            </NumberBtn>
            <NumberBtn className="mo_disnone">
              <span>3</span>
            </NumberBtn>
          </NumberBtnWrapper>
          <ContWrapper className="cont_wrapper">
            <ContText className="mo_text"> Web3 Games </ContText>
            <GotoBtn
              className="mo_goto_btn"
              onClick={() => {
                navigate('/web3game');
              }}
            >
              <img src={lineGotoArrow} alt="lineGotoArrow" />
            </GotoBtn>
          </ContWrapper>
        </LollingWrapper>
        <LollingWrapper
          onClick={() => handleLolling(3)}
          onMouseEnter={() => handleLolling(3)}
          className={lollingCount === 3 ? 'active' : ''}
        >
          <NumberBtnWrapper className="numbtn_wrapper">
            <NumberBtn className="mo_disnone">
              <span>1</span>
            </NumberBtn>
            <NumberBtn className="mo_disnone">
              <span>2</span>
            </NumberBtn>
            <NumberBtn className="select_btn">
              <span>3</span>
            </NumberBtn>
          </NumberBtnWrapper>
          <ContWrapper className="cont_wrapper">
            <ContText className="mo_text"> NFTs </ContText>
            <GotoBtn
              className="mo_goto_btn"
              onClick={() => {
                navigate('/nfts');
              }}
            >
              <img src={lineGotoArrow} alt="lineGotoArrow" />
            </GotoBtn>
          </ContWrapper>
        </LollingWrapper>
      </LollingContainer>
    </LollingContContainer>
  );
};

export default Lolling;
