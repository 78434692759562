import styled, { css } from 'styled-components';
import bg from '../../assets/background/blue_dust.svg';
import looper from '../../assets/background/Looper.svg';
import PageTitle from '../../common/PageTitle';
import { DarkGrayGrident, HotPinkGrident, LightGrayGrident, LightPinkGrident } from '../../ common/box/styles';
import ScheduleReleaseBg from './ScheduleReleaseBg';
import useEnterPage from '../../hooks/useEnterPage';

const BgContainer = styled.div`
  width: 100%;
  background:
    url(${bg}) no-repeat calc(50% + 400px) -350px,
    url(${looper}) no-repeat calc(50% + 400px) -300px;
  background-size: 1000px;
  display: flex;
  justify-content: center;
  position: relative;

  @media (max-width: 768px) {
    background:
      url(${bg}) no-repeat calc(50% - 180px) -250px,
      url(${looper}) no-repeat calc(50%) 0px;
    background-size: 1000px, 800px;
  }
`;

const SRContainer = styled.div`
  max-width: 1200px;
  margin-left: 120px;
  margin-right: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const MT = styled.div`
  margin-top: 150px;
  margin-bottom: 100px;

  @media (max-width: 768px) {
    margin-bottom: 27px;
  }
`;

const DateContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 140px;
`;

const DateChart = styled.section`
  display: grid;
  grid-gap: 15px 10px;
  grid-template-columns: repeat(19, 1fr);
  align-items: center;

  @media (max-width: 768px) {
    grid-column-gap: 2px;
  }
`;

const commonChart = css`
  height: 43px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 768px) {
    height: 20px;
    font-size: 0.5rem;
    padding: 0px;
  }
`;

const HotPinkChart = styled(HotPinkGrident)`
  ${commonChart}
`;

const LightPinkChart = styled(LightPinkGrident)`
  ${commonChart}
`;

const LightGrayChart = styled(LightGrayGrident)`
  ${commonChart}
`;

const PrivateCLIFF = styled(LightGrayChart)`
  margin-top: 30px;
  grid-column: 2 / span 6;

  @media (max-width: 768px) {
    margin-top: 0px;
  }
`;

const PrivateMonth = styled(LightPinkChart)`
  margin-top: 30px;
  grid-column: 8 / span 8;

  @media (max-width: 768px) {
    margin-top: 0px;
  }
`;

const Liquildity = styled(HotPinkChart)`
  grid-column: 2 / span 18;
`;

const OperationCLIFF = styled(LightGrayChart)`
  grid-area: 4 / 2 / span 1 / span 8;
`;

const OperationMonth = styled(LightPinkChart)`
  grid-area: 4 / 10 / span 1 / span 10;
`;

const P_ACLIFF = styled(LightGrayChart)`
  height: 52px;
  grid-area: 5 / 2 / span 1 / span 6;

  @media (max-width: 768px) {
    height: 20px;
  }
`;

const P_AMonth = styled(LightPinkChart)`
  height: 52px;
  grid-area: 5 / 8 / span 1 / span 8;

  @media (max-width: 768px) {
    height: 20px;
  }
`;

const M_DMonth = styled(LightPinkChart)`
  height: 52px;
  grid-area: 6 / 2 / span 1 / span 18;

  @media (max-width: 768px) {
    height: 20px;
  }
`;

const TreasuryCLIFF = styled(LightGrayChart)`
  grid-area: 7 / 2 / span 1 / span 8;
`;

const TreasuryMonth = styled(LightPinkChart)`
  grid-area: 7 / 10 / span 1 / span 10;
`;

const EcosyMonth = styled(LightPinkChart)`
  grid-area: 8 / 2 / span 1 / span 18;
`;

const UtilityMonth = styled(LightPinkChart)`
  grid-area: 9 / 2 / span 1 / span 18;
`;

const InitialP = styled.p`
  font-size: 1.5rem;
  font-weight: 400;
  text-align: right;
  margin-top: 22px;

  @media (max-width: 768px) {
    font-size: 0.5625rem;
    color: #8c8c8c;
  }
`;

const TitleP = styled.p`
  font-size: 1.25rem;
  font-weight: 500;
  color: #b8b8b8;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 43px;
  text-align: center;
  margin-right: 20px;

  @media (max-width: 768px) {
    font-size: 0.5625rem;
    margin-right: 5px;
    justify-content: flex-start;
    text-align: left;
  }
`;

const SSTP = styled(TitleP)`
  color: #fff;
  font-size: 1.875rem;
  font-weight: 700;
  grid-column-start: 1;

  @media (max-width: 768px) {
    font-size: 0.875rem;
    margin-bottom: 0px;
  }
`;

const PrivateP = styled(TitleP)`
  margin-top: 30px;
  grid-column-start: 1;
  grid-row-start: 2;

  @media (max-width: 768px) {
    margin-top: 0px;
  }
`;

const LiquidityP = styled(TitleP)`
  grid-column-start: 1;
  grid-row-start: 3;
`;

const OperationP = styled(TitleP)`
  grid-column-start: 1;
  grid-row-start: 4;
`;

const PartnerP = styled(TitleP)`
  grid-column-start: 1;
  grid-row-start: 5;
`;

const MarketingP = styled(TitleP)`
  grid-column-start: 1;
  grid-row-start: 6;
`;

const TreasuryP = styled(TitleP)`
  grid-column-start: 1;
  grid-row-start: 7;
`;

const EcosystemP = styled(TitleP)`
  grid-column-start: 1;
  grid-row-start: 8;
`;

const UtilityP = styled(TitleP)`
  grid-column-start: 1;
  grid-row-start: 9;
`;

const ScheduleRelease = () => {
  const CLIFF = 'CLIFF';
  const Month = 'MONTHLY LINEAR VEST';
  const dateArr = [
    { date: 1, id: 1 },
    { date: 2, id: 2 },
    { date: 3, id: 3 },
    { date: 4, id: 4 },
    { date: 5, id: 5 },
    { date: 6, id: 6 },
    { date: 9, id: 7 },
    { date: 12, id: 8 },
    { date: 15, id: 9 },
    { date: 18, id: 10 },
    { date: 20, id: 11 },
    { date: 22, id: 12 },
    { date: 24, id: 13 },
    { date: 26, id: 14 },
    { date: 30, id: 15 },
    { date: 36, id: 16 },
    { date: 42, id: 17 },
    { date: 48, id: 18 },
  ];

  useEnterPage();

  return (
    <>
      <BgContainer>
        <SRContainer>
          <MT>
            <PageTitle
              styles={{
                fontalign: 'center',
              }}
              className="distribution"
            >
              Schedule Release
            </PageTitle>
          </MT>
          <DateContainer>
            <DateChart>
              <SSTP>SST</SSTP>
              <PrivateP>Private</PrivateP>
              <LiquidityP>Liquidity</LiquidityP>
              <OperationP>Operation</OperationP>
              <PartnerP>
                Partners
                <br />& Advisors
              </PartnerP>
              <MarketingP>
                Marketing <br /> & Dev{' '}
              </MarketingP>
              <TreasuryP>Treasury</TreasuryP>
              <EcosystemP>Ecosystem Fund</EcosystemP>
              <UtilityP>Utility Pool</UtilityP>
              {dateArr.map((el) => (
                <DarkGrayGrident key={el.id}>{el.date}</DarkGrayGrident>
              ))}
              <PrivateCLIFF>{CLIFF}</PrivateCLIFF>
              <PrivateMonth>{Month}</PrivateMonth>
              <Liquildity>100% UNLOCKED</Liquildity>
              <OperationCLIFF>{CLIFF}</OperationCLIFF>
              <OperationMonth>{Month}</OperationMonth>
              <P_ACLIFF>{CLIFF}</P_ACLIFF>
              <P_AMonth>{Month}</P_AMonth>
              <M_DMonth>{Month}</M_DMonth>
              <TreasuryCLIFF>{CLIFF}</TreasuryCLIFF>
              <TreasuryMonth>{Month}</TreasuryMonth>
              <EcosyMonth>{Month}</EcosyMonth>
              <UtilityMonth>{Month}</UtilityMonth>
            </DateChart>
            <InitialP>Initial Token supply: 37,852,083 SST</InitialP>
          </DateContainer>
        </SRContainer>
        <ScheduleReleaseBg />
      </BgContainer>
    </>
  );
};

export default ScheduleRelease;
