import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useLocationEndpoint from './useLocationEndpoint';

const useGotoTarget = () => {
  const navigation = useNavigate();
  const ispath = useLocationEndpoint();

  const handleGotoWrap = (targetId: string) => {
    if (ispath !== '') {
      navigation(`/`);
      // localStorage에 저장하기
      localStorage.setItem('TARGET', JSON.stringify(targetId));
    }
  };

  useEffect(() => {
    const localTarget = localStorage.getItem('TARGET');
    const strTarget = localTarget && JSON.parse(localTarget);
    const element = document.querySelector(`#${strTarget}`);

    if (element) {
      const yOffset = -102;
      const y = element.getBoundingClientRect().top + window.scrollY + yOffset;
      window.scrollTo({ top: y, behavior: 'smooth' });
    }

    return () => {
      // 1초 후 localStorage에 저장한 TARGET 삭제
      setTimeout(() => {
        localStorage.removeItem('TARGET');
      }, 1000);
    };
  }, [ispath]);

  return handleGotoWrap;
};

export default useGotoTarget;
