import styled from 'styled-components';
import TopArrow from '../../assets/TopArrow.svg';
import scrollToTop from '../../utils/scrollToTop';

const GotoTopBtn = styled.button`
  position: fixed;
  z-index: 10;
  width: 57px;
  height: 57px;
  bottom: 30px;
  right: 30px;
  border-radius: 100px;
  border: 1px solid #fff;
  padding: 0;

  @media (max-width: 768px) {
    width: 30px;
    height: 30px;

    img {
      width: 40%;
    }
  }
`;

const GotoTopButton = () => {
  return (
    <GotoTopBtn onClick={scrollToTop}>
      <img src={TopArrow} alt="top button" />
    </GotoTopBtn>
  );
};

export default GotoTopButton;
