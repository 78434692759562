import styled from 'styled-components';

export const OpacityDiv = styled.div`
  opacity: 0;
  margin-bottom: 32px;

  @media (max-width: 768px) {
    margin-bottom: 0px;
  }
`;
